import { Dede } from '../..';
import { IGameSize, Orientation } from '../../../types';
import { SpineAnimator } from '../../../../../components/spineAnimator/SpineAnimator';
import { SYMBOL_GAP, SYMBOL_HEIGHT, SYMBOLS_PER_REEL } from '../../resources/constants';
import { IEventDetails } from '../../../gameEvent';
import { DEFAULT_CHARACTER_SCALE, LANDSCAPE_CHARACTER_OFFSET_Y, PORTRAIT_CHARACTER_OFFSET_Y } from './constants';

export class MainCharacter {
  private spineAnimator?: SpineAnimator;
  private runTimeout?: NodeJS.Timeout;
  constructor(private game: Dede) {}

  async mount() {
    this.spineAnimator = new SpineAnimator('characterData', 'characterAtlas');
    this.spineAnimator.mount('idle_1', 'idle_1');

    this.game.onTumble.addEventListener(this.handleTumble);
    // this.spineAnimator.spine!.state.data.defaultMix = 0.2;

    this.spineAnimator.spine!.eventMode = 'static';
    this.game.app.stage.addChild(this.spineAnimator.spine!);
    this.game.onResize.addEventListener(this.handleResize);
    this.game.onTumble.addEventListener(this.handleTumble);
    this.handleResize();
  }

  handleResize = () => {
    const orientation = this.game.getOrientation();
    if (!this.spineAnimator!.spine)
      return;
    if (orientation === 'portrait') {
      this.spineAnimator!.spine!.scale = DEFAULT_CHARACTER_SCALE;
      this.spineAnimator!.spine!.x = 150;
      this.spineAnimator!.spine!.y
        = this.game.reelsManager.endPositionY
        - (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1)
        - PORTRAIT_CHARACTER_OFFSET_Y;
    }
    else {
      let scale = DEFAULT_CHARACTER_SCALE;
      this.spineAnimator!.spine!.scale = scale;
      this.spineAnimator!.spine!.x = 180;
      if (this.game.height > this.spineAnimator?.spine.height!)
        this.spineAnimator!.spine!.y
          = (this.game.height - this.spineAnimator?.spine.height!) / 2 + LANDSCAPE_CHARACTER_OFFSET_Y;
      else {
        scale *= this.game.height / this.spineAnimator?.spine.height!;
        this.spineAnimator!.spine!.scale = scale;
        this.spineAnimator!.spine!.y = LANDSCAPE_CHARACTER_OFFSET_Y * (scale / DEFAULT_CHARACTER_SCALE);
      }
    }
  };

  handleTumble = () => {
    this.spineAnimator!.spine!.state.setAnimation(0, 'win_1');
  };
}
