import { Graphics, Text } from 'pixi.js';
import { Dede } from '.';

export class InfoManager {
  container?: Graphics;
  balanceText?: Text;
  stakeText?: Text;
  rect?: Graphics;
  width = 0;

  constructor(private game: Dede) {
    game.onBalanceChange.addEventListener(() => {
      this.renderTexts();
    });
    game.onStakeChange.addEventListener(() => {
      this.renderTexts();
    });
  }

  handleResize() {
    if (!this.rect)
      return;
    this.rect.width = this.width;
    this.rect.height = 50;
    this.rect.x = (this.game.width - this.width) / 2;
    this.rect.y = this.game.height - 50;
    this.renderTexts();
  }

  renderTexts() {
    if (!this.rect)
      return;
    this.rect.removeChild(this.balanceText!);
    this.rect.removeChild(this.stakeText!);
    this.balanceText = new Text();
    this.rect.addChild(this.balanceText);
    this.balanceText.text = 'Balance: $' + this.game.balance;
    this.balanceText.style = { fill: 0xffffff, fontSize: 30 };
    this.balanceText.x = 300;
    this.balanceText.y = 10;
    this.balanceText.alpha = 10000;

    this.stakeText = new Text();
    this.rect.addChild(this.stakeText);
    this.stakeText.text = 'Bet: $' + (this.game.stake * (this.game.anteBetActive ? 1.25 : 1)).toFixed(2);
    this.stakeText.style = { fill: 0xffffff, fontSize: 30 };
    this.stakeText.x = this.width - this.stakeText.width - 300;
    this.stakeText.y = 10;
    this.stakeText.alpha = 10000;
    this.game.app.stage.addChild(this.container!);
  }

  mount() {
    const graphics = new Graphics();
    this.container = graphics;
    this.width = this.game.width;
    const height = 50;
    const x = (this.game.width - this.width) / 2;
    const y = this.game.height - height;
    graphics.x = x;
    graphics.y = y;
    graphics.width = this.width;
    graphics.height = height;
    const rect = graphics.rect(0, 0, this.width, height);
    rect.fill(0x000000);
    rect.alpha = 0.8;
    this.rect = rect;
    this.handleResize();
    this.game.onResize.addEventListener(() => {
      this.handleResize();
    });
  }
}
