import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';

export class BigWinManager {
  popupContainer?: Graphics;
  timeout?: NodeJS.Timeout;
  freeSpinStatus?: IFreeSpinStatus;

  constructor(private game: Dede) {
    game.onSpinComplete.addEventListener((event, { totalWinAmount, tumbleWinAmount }) => {
      console.log('SpinWinAmountChanged', totalWinAmount, tumbleWinAmount);
      if (tumbleWinAmount > this.game.stake * (this.game.config?.limits.winSizes?.[2] || 20))
        this.mountPopup(totalWinAmount, 'MEGA WIN');
      else if (tumbleWinAmount > this.game.stake * (this.game.config?.limits.winSizes?.[1] || 10))
        this.mountPopup(tumbleWinAmount, 'SUPER WIN');
      else if (tumbleWinAmount > this.game.stake * (this.game.config?.limits.winSizes?.[0] || 5))
        this.mountPopup(tumbleWinAmount, 'BIG WIN');
    });

    game.onFreeSpinChange.addEventListener((event, status) => {
      this.freeSpinStatus = status;
    });
  }

  initOkButton() {
    const buttonTexture = Texture.from('okButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.popupContainer!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.anchor.set(0.5);
    button.x = this.popupContainer!.width / 2 - 40;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.unmountPopup();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  mountPopup(winAmount: number, winMessage: string) {
    this.popupContainer?.destroy();

    this.game.paused = true;
    console.log('mountbigwinUI');
    const graphics = new Graphics();
    this.popupContainer = graphics;
    const width = 600;
    const height = 200;
    const x = (this.game.width - width) / 2;
    const y = (this.game.height - height) / 2;
    graphics.x = x;
    graphics.y = y;
    graphics.width = width;
    graphics.height = height;
    const background = graphics.roundRect(0, 0, width, height, 20);
    const rect = graphics.roundRect(0, 0, width, height, 20);
    background.fill(0x9d34aa);
    background.alpha = 0.6;

    const text = new Text();
    rect.addChild(text);
    text.text = winMessage + '!! You won ' + winAmount + ' USD!';
    text.style = { fill: 0xffffff, fontSize: 40 };
    text.x = (this.popupContainer!.width - text.width) / 2;
    text.y = 40;
    text.alpha = 10000;
    this.initOkButton();
    this.game.app.stage.addChild(graphics);
  }

  unmountPopup() {
    this.game.paused = false;
    this.popupContainer?.destroy();
  }
}
