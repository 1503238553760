import cachedSettingsManager from '../cachedSettingsManager';
import { consoleInterface as logger } from './privateLogger';

const accamaxDebug = {
  settings: cachedSettingsManager,
  logger,
};

window.accamaxDebug = accamaxDebug;

export default accamaxDebug;
