import { Container, Graphics, Sprite, Texture, Ticker } from 'pixi.js';
import { SCATTER_WIDTH, SHOW_BORDERS, SYMBOL_GAP, SYMBOL_HEIGHT, SYMBOL_WIDTH } from '../../resources/constants';
import Symbol from '../symbol';
import { IReelEvents } from './types';
import { Dede } from '../..';

export default class Reel {
  symbols: Symbol[] = [];
  slotTextures: Texture[] = [];
  moving = false;
  explodingSymbols: Symbol[] = [];
  explodedSymbols: Symbol[] = [];
  completeTimeout: NodeJS.Timeout | null = null;

  constructor(
    public game: Dede,
    public container: Container,
    public index: number,
    private symbolCount: number,
    private startY: number,
    private randomNumbers: number[],
    private events: IReelEvents,
  ) {
    this.symbols = [];

    // Load the textures

    this.slotTextures = [
      Texture.from('s1'),
      Texture.from('s2'),
      Texture.from('s3'),
      Texture.from('s4'),
      Texture.from('s5'),
      Texture.from('s6'),
      Texture.from('s7'),
      Texture.from('s8'),
      Texture.from('s9'),
      Texture.from('s10'),
    ];
  }

  addSymbol() {
    if (!this.container)
      return;
    const symbolType = this.randomNumbers.pop();
    if (symbolType === undefined) {
      console.log('symbolType is undefined');
      return;
    }
    const sprite
      = symbolType > 100000 ? new Sprite(Texture.from('multiplier')) : new Sprite(this.slotTextures[symbolType]);
    this.container.addChild(sprite);
    const isScatter = symbolType === this.game.config.scatterSymbol;
    sprite.width = isScatter ? SCATTER_WIDTH : SYMBOL_WIDTH;
    sprite.height = isScatter ? SCATTER_WIDTH : SYMBOL_HEIGHT;
    if (SHOW_BORDERS) {
      let border = new Graphics();
      border.rect(0, 0, sprite.width, sprite.height);
      sprite.addChild(border);
    }

    sprite.y = this.startY + (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0);
    sprite.zIndex = isScatter ? 1 : 0;
    console.log(
      'starty',
      sprite.y,
      this.startY,
      this.game.reelsManager.endPositionY
      - (SYMBOL_HEIGHT + SYMBOL_GAP) * this.symbols.length
      + (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
    );

    const symbol = new Symbol(
      this,
      symbolType,
      sprite,
      {
        start: {
          x: this.index * (SYMBOL_WIDTH + SYMBOL_GAP) + (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
          y: sprite.y,
        },
        end: {
          y:
            this.game.reelsManager.endPositionY
            - (SYMBOL_HEIGHT + SYMBOL_GAP) * this.symbols.length
            + (isScatter ? (SYMBOL_HEIGHT - SCATTER_WIDTH) / 2 : 0),
        },
      },

      {
        onDestroy: () => {
          this.container?.removeChild(sprite);
          this.symbols = this.symbols.filter((s) => s !== symbol);
          if (this.symbols.length === 0) {
            this.events.onDestroy();
          }
        },
        onExplodeComplete: () => {
          this.explodedSymbols.push(symbol);
          if (this.explodedSymbols.length === this.explodingSymbols.length) {
            this.events.onExplodeComplete();
          }
        },
        onFallComplete: () => {
          const allCompleted = this.symbols.every((symbol) => !symbol.falling);
          if (allCompleted) {
            this.moving = false;
            if (this.completeTimeout)
              clearTimeout(this.completeTimeout);
            this.completeTimeout = setTimeout(() => {
              this.events.onFallComplete();
            }, 100);
          }
        },
      },
      this.symbols.length,
    );

    this.symbols.push(symbol);
  }

  moveAfterExplode() {
    this.moving = true;
    const unExplodedSymbols = this.symbols
      .filter((s) => this.explodedSymbols.indexOf(s) === -1)
      .sort((a, b) => a.index - b.index);
    this.explodingSymbols.forEach((el) => el.deselect());
    this.symbols = unExplodedSymbols;
    unExplodedSymbols.forEach((symbol, index) => {
      symbol.fallDown(this.game.reelsManager.endPositionY - (SYMBOL_HEIGHT + SYMBOL_GAP) * index);
      symbol.index = index;
    });
    for (let i = unExplodedSymbols.length; i < this.symbolCount; i++) {
      this.addSymbol();
    }
  }

  destroy() {
    this.symbols.forEach((symbol) => {
      symbol.destroy();
    });
  }

  async start() {
    this.symbols = [];
    this.moving = true;

    for (let i = 0; i < this.symbolCount; i++) {
      this.addSymbol();
      await new Promise((resolve) => setTimeout(resolve, 200));
    }
  }

  update(ticker: Ticker) {
    this.symbols.forEach((symbol) => {
      symbol.update(ticker);
    });
  }
}
