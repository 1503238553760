import { Progress } from 'antd';
import React, { memo } from 'react';
import styles from './index.module.css';

const GameLoader: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className={styles.container}>
      <Progress style={{ color: 'white' }} type="circle" percent={Number((progress * 100).toFixed(2))} />
    </div>
  );
};

export default memo(GameLoader);
