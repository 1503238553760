import { Container, Sprite, Text, Texture } from 'pixi.js';
import { IPosition } from '../../../types';
import { DropShadowFilter } from 'pixi-filters';

class Button {
  pressed = false;
  background: Sprite;
  content!: Sprite;
  container: Sprite;
  private _text!: Text;
  private _disabled = false;
  constructor(
    parent: Container,
    buttonOptions: {
      backgroundTexture: string,
      texture?: string,
      text?: string,
      fontSize?: number,
      onClick: () => void,
      contentPosition?: IPosition,
      backgroundPosition: IPosition,
    },
  ) {
    this.container = new Sprite();
    this.background = new Sprite(Texture.from(buttonOptions.backgroundTexture));
    this.background.width = buttonOptions.backgroundPosition.width || 0;
    this.background.height = buttonOptions.backgroundPosition.height || 0;
    this.container.x = buttonOptions.backgroundPosition.x;
    this.container.y = buttonOptions.backgroundPosition.y;
    this.background.anchor.set(0.5);

    this.container.addChild(this.background);
    if (buttonOptions.texture) {
      this.content = new Sprite(Texture.from(buttonOptions.texture));
      if (buttonOptions.contentPosition?.width)
        this.content.width = buttonOptions.contentPosition.width;
      if (buttonOptions.contentPosition?.height)
        this.content.height = buttonOptions.contentPosition.height;
      if (buttonOptions.contentPosition?.x)
        this.content.x = buttonOptions.contentPosition.x;
      if (buttonOptions.contentPosition?.y)
        this.content.y = buttonOptions.contentPosition.y;
      this.content.anchor.set(0.5);
      this.background.addChild(this.content);
    }

    if (buttonOptions.text) {
      const text = new Text();
      this._text = text;
      text.style = {
        fontFamily: 'Arial',
        fontSize: buttonOptions.fontSize || 24,
        fill: 0xffffff,
        align: 'center',
      };
      text.text = buttonOptions.text;
      text.anchor.set(0.5);
      text.x = buttonOptions.contentPosition?.x || 0;
      text.y = buttonOptions.contentPosition?.y || 0;
      this.background.addChild(text);
    }

    const shadow = new DropShadowFilter({
      color: 0xffffff, // White shadow
      blur: 12, // Blur effect
      alpha: 0.7, // Transparency
      quality: 12, // Quality of the shadow
    });

    this.background.filters = [shadow];

    this.container.interactive = true;
    this.container.cursor = 'pointer'; // Set cursor to pointer on hover
    this.container.anchor.set(0.5);
    parent.addChild(this.container);

    this.container.on('pointerdown', () => {
      this.container.scale.set(this.container.scale.x * 0.9);
      this.container.tint = 0xaaaaaa;
      this.pressed = true;
    });
    this.container.on('pointerup', () => {
      if (!this.pressed)
        return;
      this.container.scale.set(this.container.scale.x / 0.9);
      this.container.tint = 0xffffff;
      buttonOptions.onClick();
      this.pressed = false;
    });
    this.container.on('pointerupoutside', () => {
      if (!this.pressed)
        return;
      this.container.scale.set(this.container.scale.x / 0.9);
      this.container.tint = 0xffffff;
      this.pressed = false;
    });
  }

  set text(text: string) {
    this._text!.text = text;
  }

  get text() {
    return this._text.text;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.container.interactive = !disabled;
    this.container.cursor = disabled ? 'not-allowed' : 'pointer';
  }

  get disabled() {
    return this._disabled;
  }

  destroy() {
    this.container.destroy();
  }
}

export default Button;
