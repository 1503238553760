export const STANDARD_FPS = 20;
export const SHOW_BORDERS = false;
export const DESTROY_Y_LIMIT = 2000;
export const SELECTION_TIME = 1000; // ms
export const GRAVITY = 2.5;
export const WIND = 0;
export const SYMBOL_TYPES = 4;
export const SYMBOLS_PER_REEL = 5;
export const REELS_COUNT = 6;
export const REELS_MARGIN_LEFT_DESKTOP = 100;
export const REELS_MARGIN_LEFT_MOBILE = 40;

export const REELS_MARGIN_TOP_DESKTOP = 100;
export const REELS_MARGIN_TOP_MOBILE = 0;

export const SYMBOL_HEIGHT = 165;
export const SYMBOL_WIDTH = 165;

export const SCATTER_HEIGHT = 217;
export const SCATTER_WIDTH = 217;

export const LONG_DIMENSION = 1920;
export const SHORT_DIMENSION = 1080;

export const SYMBOL_GAP = 0;

export const REELS_MASK_OFFSET = 30;
export const PORTRAIT_LOGO_OFFSET_Y = 220;
export const PORTRAIT_LOGO_OFFSET_X = 10;

export const REELS_CONTAINER_OFFSET_Y = 190;
export const REELS_CONTAINER_OFFSET_X = 400;

export const REELS_BACKGROUND_OFFSET_Y = 10;
export const REELS_BACKGROUND_OFFSET_X = 70;

export const NEXT_SYMBOL_DELAY = 100;
export const SYMBOL_SHAKE_MAGNITUDE = 5;
export const SYMBOL_SHAKE_DURATION = 200;
