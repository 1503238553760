import { Container, Graphics, Sprite, Texture, Ticker } from 'pixi.js';
import Reel from './models/reel';
import delay from 'delay';
import { Dede } from '.';
import {
  NEXT_SYMBOL_DELAY,
  PORTRAIT_LOGO_OFFSET_X,
  PORTRAIT_LOGO_OFFSET_Y,
  REELS_BACKGROUND_OFFSET_X,
  REELS_BACKGROUND_OFFSET_Y,
  REELS_CONTAINER_OFFSET_X,
  REELS_CONTAINER_OFFSET_Y,
  REELS_COUNT,
  REELS_MASK_OFFSET,
  SELECTION_TIME,
  SHORT_DIMENSION,
  SYMBOL_GAP,
  SYMBOL_HEIGHT,
  SYMBOL_SHAKE_DURATION,
  SYMBOL_SHAKE_MAGNITUDE,
  SYMBOL_WIDTH,
  SYMBOLS_PER_REEL,
} from './resources/constants';
import { IGameOutcome } from './service/types';

export class ReelsManager {
  private reels: Reel[] = [];
  private destroyedReelsCount = 0;
  private fallCompleteTimeout?: NodeJS.Timeout;
  private randomNumbers: number[][] = [];
  container = new Graphics();
  symbolsContainer = new Container();
  mask = new Graphics();
  backgroundSprite = new Sprite();
  containerSprite = new Sprite();
  logoSprite = new Sprite();

  startPositionY = 0;
  endPositionY = 0;

  constructor(
    private game: Dede,
    private events: {
      onDestroy: () => void,
      onFallComplete: (multiplier: number, outcome?: IGameOutcome) => void,
    },
  ) {
    this.symbolsContainer.zIndex = 1;
    this.container.addChild(this.symbolsContainer);
  }

  loadNumbers(incomingNumber: number[][], clearPrevious: boolean, winningSymbols?: number[]) {
    let reversed = incomingNumber;
    if (this.randomNumbers.length === 0) {
      console.log('incomingNumber', JSON.parse(JSON.stringify(this.randomNumbers)));
      this.randomNumbers = reversed;
    }
    else if (clearPrevious) {
      this.randomNumbers.forEach((reelNumbers, i) => {
        reelNumbers.push(...reversed[i]);
      });
    }
    else {
      reversed.forEach((reelNumbers, i) => {
        const newSymbols = reelNumbers.slice(
          0,
          reelNumbers.length
          - this.reels[i].symbols.filter(
            (symbol) => !winningSymbols!.includes(symbol.symbolType) && symbol.symbolType < 100000,
          ).length,
        );

        this.randomNumbers[i].push(...newSymbols);
      });
    }
  }

  processOutcome(clearPrevious: boolean, winningSymbols?: number[]) {
    if (!clearPrevious)
      this.game.prevOutcome = this.game.currentOutcome;
    else
      this.game.prevOutcome = undefined;
    this.game.currentOutcome = this.game.outcomes.shift();
    if (this.game.currentOutcome) {
      this.loadNumbers(this.game.currentOutcome.symbols, clearPrevious, winningSymbols);
    }
  }

  createReelsMask() {
    const { height, width, x, y } = this.backgroundSprite;
    this.mask?.destroy?.();
    this.mask = new Graphics();
    this.mask.rect(
      x - REELS_MASK_OFFSET,
      y - REELS_MASK_OFFSET,
      width + REELS_MASK_OFFSET * 2,
      height + REELS_MASK_OFFSET * 2,
    );
    this.mask.fill('transparent');
    this.container.addChild(this.mask);
    this.symbolsContainer.mask = this.mask;
  }

  handleResize = () => {
    let scale = 1;
    const orientation = this.game.getOrientation();

    if (orientation === 'portrait') {
      if (this.game.width < SHORT_DIMENSION) {
        scale = this.game.width / SHORT_DIMENSION;
      }
    }
    else {
      if (this.game.height < SHORT_DIMENSION) {
        scale = this.game.height / SHORT_DIMENSION;
      }
    }

    this.startPositionY = (this.game.height - (SYMBOL_HEIGHT + SYMBOL_GAP) * SYMBOLS_PER_REEL) / 2;
    this.endPositionY = this.startPositionY + (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1);

    console.log('positions', this.startPositionY, this.endPositionY);

    this.symbolsContainer.x = (this.game.width - (SYMBOL_WIDTH + SYMBOL_GAP) * REELS_COUNT * scale) / 2;

    this.backgroundSprite.x = this.symbolsContainer.x - REELS_BACKGROUND_OFFSET_X;
    this.backgroundSprite.y = this.startPositionY - REELS_BACKGROUND_OFFSET_Y;
    this.backgroundSprite.alpha = 0.99;

    this.containerSprite.x = this.symbolsContainer.x - REELS_CONTAINER_OFFSET_X;
    this.containerSprite.y = this.startPositionY - REELS_CONTAINER_OFFSET_Y;
    if (orientation === 'portrait') {
      this.logoSprite.x = (this.game.width / scale - this.logoSprite.width) / 2 + PORTRAIT_LOGO_OFFSET_X;
      this.logoSprite.y = this.startPositionY - PORTRAIT_LOGO_OFFSET_Y;
    }
    else {
      this.logoSprite.x = 0;
      this.logoSprite.y = this.endPositionY;
    }
    this.createReelsMask();
    this.container.scale.set(scale);
  };

  async mount() {
    this.backgroundSprite = new Sprite(Texture.from('reelBackground'));
    this.containerSprite = new Sprite(Texture.from('reelContainer'));
    this.logoSprite = new Sprite(Texture.from('logo'));
    this.logoSprite.zIndex = 2;

    this.game.onFreeSpinChange.addEventListener((event, freeSpinState) => {
      if (freeSpinState.active) {
        this.containerSprite.texture = Texture.from('fsReelContainer');
      }
      else {
        this.containerSprite.texture = Texture.from('reelContainer');
      }
    });
    this.container.addChild(this.mask);
    this.container.addChild(this.backgroundSprite);
    this.container.addChild(this.containerSprite);
    this.container.addChild(this.logoSprite);
    this.game.app.stage.addChild(this.container);

    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize);

    this.reels = new Array(REELS_COUNT).fill(0).map((_, i) => {
      return new Reel(
        this.game,
        this.symbolsContainer,
        i,
        SYMBOLS_PER_REEL,
        this.startPositionY,
        this.randomNumbers[i],
        {
          onDestroy: this.handleDestroy,
          onFallComplete: this.handleFallComplete,
          onExplodeComplete: this.handleExplodeComplete,
        },
      );
    });

    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].start();
      await delay(NEXT_SYMBOL_DELAY);
    }
  }

  update(ticker: Ticker) {
    this.reels.forEach((el) => el.update(ticker));
  }

  async runReels(buyFreeSpins: boolean = false) {
    const destroyReels = async () => {
      for (let i = 0; i < this.reels.length; i++) {
        this.reels[i].destroy();
        await delay(NEXT_SYMBOL_DELAY);
      }
    };
    destroyReels();
    await this.game.serviceManager.getSpin(buyFreeSpins);
    this.processOutcome(true);
  }

  async runReelsWithOutcomes(outcomes: IGameOutcome[]) {
    console.log('Running reels with outcomes');
    this.game.outcomes = outcomes;
    this.processOutcome(true);
    for (let i = 0; i < this.reels.length; i++) {
      this.reels[i].destroy();
      await delay(NEXT_SYMBOL_DELAY);
    }
  }

  private handleExplodeComplete = async () => {
    console.log('handleExplodeComplete');
    const allCompleted = this.reels.every((reel) => reel.explodedSymbols.length === reel.explodingSymbols.length);
    if (!allCompleted)
      return;

    this.reels.forEach((reel) => {
      reel.moveAfterExplode();
    });
  };

  private handleFallComplete = async () => {
    const allCompleted = this.reels.every((reel) => !reel.moving);
    if (!allCompleted)
      return;

    if (this.fallCompleteTimeout)
      clearTimeout(this.fallCompleteTimeout);
    this.fallCompleteTimeout = setTimeout(async () => {
      const winnings = this.game.currentOutcome?.winnings?.filter(
        (winning) => winning.winningSymbol !== this.game.config.scatterSymbol,
      );
      const multiplier = this.game.currentOutcome?.multiplier || 0;
      if (winnings?.length) {
        this.game.tumble(this.game.currentOutcome!);

        this.reels.forEach((reel) => {
          reel.explodingSymbols = [];
          reel.explodedSymbols = [];
        });

        this.game.onSymbolSelection.triggerEvent();
        setTimeout(() => {
          this.game.onExplode.triggerEvent();
        }, SELECTION_TIME);

        const winningSymbols = winnings.map((winning) => winning.winningSymbol);
        winnings.forEach((winning) => {
          this.reels.forEach((reel) => {
            reel.symbols.forEach(async (symbol) => {
              if (symbol.symbolType === Number(winning.winningSymbol) || symbol.symbolType > 100000) {
                reel.explodingSymbols.push(symbol);
                symbol.select();
                symbol.shake(SYMBOL_SHAKE_MAGNITUDE, SYMBOL_SHAKE_DURATION);
                await delay(SELECTION_TIME);
                symbol.explode();
              }
            });
          });
        });

        console.log('currentOutcomecurrentOutcome', this.game.currentOutcome);
        this.events.onFallComplete(multiplier, this.game.currentOutcome);
        this.processOutcome(false, winningSymbols);
      }
      else {
        console.log('else currentOutcomecurrentOutcome', this.game.currentOutcome);
        this.events.onFallComplete(multiplier, this.game.currentOutcome);
      }
    }, 100);
  };

  private handleDestroy = async () => {
    this.destroyedReelsCount++;
    if (this.destroyedReelsCount === REELS_COUNT) {
      this.destroyedReelsCount = 0;
      this.symbolsContainer.removeChildren();

      const startNextSpin = async () => {
        for (let i = 0; i < this.reels.length; i++) {
          this.reels[i].start();
          await delay(NEXT_SYMBOL_DELAY);
        }
        this.events.onDestroy();
        this.game.onPendingSpinResponseChange.removeEventListener(startNextSpin);
      };
      if (!this.game.pendingSpinResponse)
        await startNextSpin();
      else {
        this.game.onPendingSpinResponseChange.addEventListener(startNextSpin);
      }
    }
  };

  getDynamicBounds() {
    const x = this.symbolsContainer.x;
    const y = this.endPositionY - (SYMBOL_HEIGHT + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1);
    const width = (SYMBOL_WIDTH + SYMBOL_GAP) * REELS_COUNT - SYMBOL_GAP;
    const height = (SYMBOL_HEIGHT + SYMBOL_GAP) * SYMBOLS_PER_REEL - SYMBOL_GAP;
    return { x, y, width, height };
  }
}
