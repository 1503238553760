export const dedeAssets = [
  { alias: 'okButton', src: '/assets/games/dede/okButton.png' },
  { alias: 'cancelButton', src: '/assets/games/dede/cancelButton.png' },
  { alias: 'background', src: '/assets/games/dede/background.webp' },
  { alias: 'backgroundFreeSpin', src: '/assets/games/dede/backgroundFreespin.webp' },
  { alias: 'multiplier', src: '/assets/games/dede/m1.webp' },
  { alias: 'spineboyData', src: '/spineboy-pro.json' },
  { alias: 'spineboyAtlas', src: '/spineboy-pma.atlas' },
  { alias: 'characterData', src: '/assets/games/dede/character.json' },
  { alias: 'characterAtlas', src: '/assets/games/dede/character.atlas' },
  { alias: 'backgroundMusic', src: '/assets/games/dede/background.mp3', loadLazy: true },
  { alias: 'explodeMusic', src: '/assets/games/dede/explode.mp3', loadLazy: true },
  { alias: 'landingMusic', src: '/assets/games/dede/landing.mp3', loadLazy: true },
  { alias: 'selectMusic', src: '/assets/games/dede/sword.mp3', loadLazy: true },
  { alias: 'clickMusic', src: '/assets/games/dede/click.mp3', loadLazy: true },
  { alias: '-', src: '/assets/games/dede/-.webp' },
  { alias: '+', src: '/assets/games/dede/+.webp' },
  { alias: 'autoPlay', src: '/assets/games/dede/autoPlay.webp' },
  { alias: 'bonusButtonBackground', src: '/assets/games/dede/bonusButtonBackground.webp' },
  { alias: 'buttonBackground', src: '/assets/games/dede/buttonBackground.webp' },
  { alias: 'spinBackground', src: '/assets/games/dede/spinBackground.webp' },
  { alias: 'turboSpinBackground', src: '/assets/games/dede/turboSpinBackground.webp' },
  { alias: 'betAmountBackground', src: '/assets/games/dede/betAmountBackground.webp' },
  { alias: 'turboSpin', src: '/assets/games/dede/turboSpin.webp' },
  { alias: 'spin', src: '/assets/games/dede/spin.webp' },
  { alias: 'spinContainer', src: '/assets/games/dede/spinContainer.webp' },
  { alias: 'buyBonus', src: '/assets/games/dede/buyBonus.webp' },
  { alias: 'goldenBet', src: '/assets/games/dede/goldenBet.webp' },
  { alias: 'infoButton', src: '/assets/games/dede/infoButton.webp' },
  { alias: 'spinButton', src: '/assets/games/dede/spinButton.webp' },
  { alias: 'character', src: '/assets/games/dede/characterOld.webp' },
  { alias: 's1', src: '/assets/games/dede/s1.webp' },
  { alias: 's2', src: '/assets/games/dede/s2.webp' },
  { alias: 's3', src: '/assets/games/dede/s3.webp' },
  { alias: 's4', src: '/assets/games/dede/s4.webp' },
  { alias: 's5', src: '/assets/games/dede/s5.webp' },
  { alias: 's6', src: '/assets/games/dede/s6.webp' },
  { alias: 's7', src: '/assets/games/dede/s7.webp' },
  { alias: 's8', src: '/assets/games/dede/s8.webp' },
  { alias: 's9', src: '/assets/games/dede/s9.webp' },
  { alias: 's10', src: '/assets/games/dede/s10.webp' },
  { alias: 'reelBackground', src: '/assets/games/dede/reelBackground.webp' },
  { alias: 'logo', src: '/assets/games/dede/logo.webp' },
  { alias: 'fsReelContainer', src: '/assets/games/dede/fsReelContainer.webp' },
  { alias: 'reelContainer', src: '/assets/games/dede/reelContainer.webp' },
  { alias: 'startButton', src: '/assets/games/dede/start_button.png' },
  { alias: 'symbolSelect', src: '/assets/games/dede/payline.webp' },
  { alias: 'explodeAnimation', src: '/assets/games/dede/mc.json' },
];
