import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';
import { IGameSpinResponse } from './service/types';
import { IEventDetails } from '../gameEvent';

export class FreeSpinManager {
  private freeSpins: any[] = [];
  private numberOfFreeSpinsTriggered = 0;
  popupContainer?: Graphics;
  totalMultiplierContainer?: Graphics;
  totalMultiplierText?: Text;
  resultContainer?: Graphics;

  remainingFreeSpinContainer?: Graphics;
  remainingFreeSpinText?: Text;
  startFreeSpinButton?: Sprite;
  freeSpinIndex = 0;

  constructor(private game: Dede) {
    game.onFreeSpinChange.addEventListener(() => {
      this.updateTotalMultiplier();
    });
  }

  processSpinResponse(spinResponse: IGameSpinResponse) {
    if (spinResponse.freeSpinsTriggered) {
      const startFreeSpin = () => {
        this.freeSpinIndex = 0;
        this.game.freeSpinWinAmount = 0;

        spinResponse.freeSpins.forEach((spin, x) => {
          const prevSpin = spinResponse.freeSpins[x - 1];
          spin.outcomes.forEach((outcome, index) => {
            outcome.isLastOutcome = index === spin.outcomes.length - 1;
            const spinData: any = {
              ...spin,
            };
            delete spinData.outcomes;
            outcome.spinData = spinData;
            outcome.isFreeSpin = true;
            outcome.totalWinAmount = outcome.isLastOutcome
              ? spin.runningTotal
              : (prevSpin?.runningTotal || 0) + outcome.runningTotal;
            outcome.tumbleWinAmount = outcome.isLastOutcome ? spin.winAmount : outcome.runningTotal;

            outcome.isLastFreeSpinOutcome
              = x === spinResponse.freeSpins.length - 1 && index === spin.outcomes.length - 1;
          });
        });
        this.freeSpins = spinResponse.freeSpins;
        this.numberOfFreeSpinsTriggered = spinResponse.freeSpins.length;

        const spinNext = () => {
          this.game.runFreeSpinReels(this.freeSpins[this.freeSpinIndex].outcomes);
          this.freeSpinIndex++;
        };
        spinNext();
        this.mountRemainingFreeSpinsInfo();
        this.mountTotalMultiplierInfo();

        const handleSpinComplete = (
          event: IEventDetails,
          params: { tumbleWinAmount: number, totalWinAmount: number },
        ) => {
          this.game.freeSpinWinAmount += params.tumbleWinAmount;
          if (this.freeSpinIndex < this.freeSpins.length) {
            spinNext();
            this.updateRemainingFreeSpins();
          }
          else {
            this.game.onSpinComplete.removeEventListener(handleSpinComplete);
            this.unmountRemainingFreeSpinsInfo();
            this.unmountTotalMultiplierInfo();
            this.game.freeSpinActive = false;
            this.mountFreeSpinResultPopup();
          }
        };
        this.game.onSpinComplete.addEventListener(handleSpinComplete);
      };
      const mountPopupCallback = () => {
        this.game.freeSpinTotalMultiplier = 1;
        this.game.freeSpinActive = true;
        this.numberOfFreeSpinsTriggered = spinResponse.numberOfFreeSpinsTriggered;
        this.mountFreeSpinStartPopup(startFreeSpin);
        this.game.onFallComplete.removeEventListener(mountPopupCallback);
      };
      this.game.onFallComplete.addEventListener(mountPopupCallback);
    }
  }

  mountRemainingFreeSpinsInfo() {
    const graphics = new Graphics();

    const text1 = new Text();
    text1.text = 'Free Spins';
    text1.style = { fill: 0xffffff, fontSize: 20 };
    text1.x = this.game.width - (text1.width + 4);
    text1.y = 202;

    let totalHeight = text1.height + 2;

    const text2 = new Text();
    text2.text = 'Left';
    text2.style = { fill: 0xffffff, fontSize: 20 };
    text2.x = this.game.width - (text2.width + 4 + (text1.width - text2.width) / 2);
    text2.y = 202 + totalHeight;

    totalHeight += text2.height + 2;

    const text3 = new Text();
    text3.text = this.numberOfFreeSpinsTriggered - this.freeSpinIndex + '';
    text3.style = { fill: 0xffffff, fontSize: 24 };
    text3.x = this.game.width - (text3.width + 4 + (text1.width - text3.width) / 2);
    text3.y = 202 + totalHeight;

    totalHeight += text3.height + 2;

    const rect = graphics.roundRect(this.game.width - (text1.width + 6), 200, text1.width + 4, totalHeight + 4, 5);
    rect.fill(0x9d34aa);
    rect.alpha = 1;

    rect.addChild(text1);
    rect.addChild(text2);
    rect.addChild(text3);
    // create button to start free spin

    this.game.app.stage.addChild(graphics);
    this.remainingFreeSpinContainer = graphics;
    this.remainingFreeSpinText = text3;
  }

  updateRemainingFreeSpins() {
    if (this.remainingFreeSpinText) {
      const remainingFreeSpins = this.numberOfFreeSpinsTriggered - this.freeSpinIndex;
      this.remainingFreeSpinText.text = remainingFreeSpins + '';
      if (remainingFreeSpins === 9)
        this.remainingFreeSpinText.x += 6;
    }
  }

  unmountRemainingFreeSpinsInfo() {
    this.remainingFreeSpinContainer?.destroy();
  }

  mountTotalMultiplierInfo() {
    const graphics = new Graphics();

    const text1 = new Text();
    text1.text = 'Total';
    text1.style = { fill: 0xffffff, fontSize: 20 };
    text1.x = this.game.width - (text1.width + 4);
    text1.y = 122;

    let totalHeight = text1.height + 2;

    const text3 = new Text();
    text1.text = 'Multiplier';
    text1.style = { fill: 0xffffff, fontSize: 20 };
    text1.x = this.game.width - (text1.width + 4);
    text1.y = 122;

    totalHeight = text3.height + 2;

    const text2 = new Text();
    text2.text = this.game.freeSpinTotalMultiplier + '';
    text2.style = { fill: 0xffffff, fontSize: 24 };
    text2.x = this.game.width - (text2.width + 4 + (text1.width - text2.width) / 2);
    text2.y = 122 + totalHeight;

    totalHeight += text2.height + 2;

    const rect = graphics.roundRect(this.game.width - (text1.width + 6), 120, text1.width + 4, totalHeight + 4, 5);
    rect.fill(0x9d34aa);
    rect.alpha = 1;

    rect.addChild(text1);
    rect.addChild(text2);
    // create button to start free spin

    this.game.app.stage.addChild(graphics);
    this.totalMultiplierContainer = graphics;
    this.totalMultiplierText = text2;
  }

  unmountTotalMultiplierInfo() {
    this.totalMultiplierContainer?.destroy();
  }

  updateTotalMultiplier() {
    if (this.totalMultiplierText) {
      this.totalMultiplierText.text = this.game.freeSpinTotalMultiplier + '';
    }
  }

  mountFreeSpinResultPopup() {
    const graphics = new Graphics();
    if (this.resultContainer)
      this.resultContainer.destroy();
    this.resultContainer = graphics;
    const width = 600;
    const height = 200;
    const x = (this.game.width - width) / 2;
    const y = (this.game.height - height) / 2;
    graphics.x = x;
    graphics.y = y;
    graphics.width = width;
    graphics.height = height;
    const background = graphics.roundRect(0, 0, width, height, 20);
    const rect = graphics.roundRect(0, 0, width, height, 20);
    background.fill(0x9d34aa);
    background.alpha = 0.6;
    const text = new Text();
    rect.addChild(text);
    if (this.game.freeSpinWinAmount)
      text.text = 'You won ' + this.game.freeSpinWinAmount + '!';
    else
      text.text = 'You won nothing!';
    text.style = { fill: 0xffffff, fontSize: 40 };
    text.x = (this.resultContainer!.width - text.width) / 2;
    text.y = 40;
    text.alpha = 100000;

    this.initOkButton(() => {
      this.unmountRemainingFreeSpinsInfo();
      this.unmountTotalMultiplierInfo();
    });
    this.game.app.stage.addChild(graphics);
  }

  initStartButton(onOk: () => void) {
    const buttonTexture = Texture.from('startButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.popupContainer!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 200;
    button.height = 80;
    button.anchor.set(0.5);
    button.x = this.popupContainer!.width / 2 - 60;
    button.y = 150;
    button.zIndex = 100;
    button.alpha = 100000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      // this.game.runReels(false);
      onOk();
      this.unmountFreeSpinPopup();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    this.startFreeSpinButton = button;
  }

  initOkButton(onOk = () => {}) {
    const buttonTexture = Texture.from('okButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.resultContainer!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.anchor.set(0.5);
    button.x = this.resultContainer!.width / 2 - 20;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.resultContainer?.destroy();
      onOk();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  mountFreeSpinStartPopup(onOk: () => void) {
    const graphics = new Graphics();
    this.popupContainer = graphics;
    const width = 600;
    const height = 200;
    const x = (this.game.width - width) / 2;
    const y = (this.game.height - height) / 2;
    graphics.x = x;
    graphics.y = y;
    graphics.width = width;
    graphics.height = height;
    const background = graphics.roundRect(0, 0, width, height, 20);
    const rect = graphics.roundRect(0, 0, width, height, 20);
    background.fill(0x9d34aa);
    background.alpha = 0.6;

    const text = new Text();
    rect.addChild(text);
    text.text = 'You won ' + this.numberOfFreeSpinsTriggered + ' free spins!';
    text.style = { fill: 0xffffff, fontSize: 40 };
    text.x = (width - text.width) / 2;
    text.y = 40;
    text.alpha = 1000;

    this.initStartButton(onOk);
    graphics.addChild(this.startFreeSpinButton!);
    this.game.app.stage.addChild(graphics);
  }

  unmountFreeSpinPopup() {
    this.popupContainer?.destroy();
  }
}
