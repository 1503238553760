import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';

export class AfterLoadManager {
  popup?: Graphics;
  onOkClick?: () => void;

  constructor(private game: Dede) {}

  mountAfterLoadScreen(onOkClick?: () => void) {
    this.onOkClick = onOkClick;
    console.log('mountAfterLoadScreen');
    const graphics = new Graphics();
    this.popup = graphics;
    const width = 600;
    const height = 200;
    const x = (this.game.width - width) / 2;
    const y = (this.game.height - height) / 2;
    graphics.x = x;
    graphics.y = y;
    graphics.width = width;
    graphics.height = height;
    const background = graphics.roundRect(0, 0, width, height, 20);
    const rect = graphics.roundRect(0, 0, width, height, 20);
    background.fill(0x9d34aa);
    background.alpha = 0.6;

    const text = new Text();
    rect.addChild(text);
    text.text = 'Start Game?';
    text.style = { fill: 0xffffff, fontSize: 40 };
    text.x = (width - text.width) / 2;
    text.y = 40;
    text.alpha = 1000;

    this.initOkButton();

    this.initCancelButton();
    this.game.app.stage.addChild(graphics);
  }

  initCancelButton() {
    const buttonTexture = Texture.from('cancelButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.popup!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.anchor.set(0.5);
    button.x = this.popup!.width / 2 - 100;
    button.y = 150;
    button.zIndex = 100;
    button.alpha = 1000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.popup?.destroy();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  initOkButton() {
    const buttonTexture = Texture.from('okButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.popup!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.anchor.set(0.5);
    button.x = this.popup!.width / 2 + 30;
    button.y = 150;
    button.zIndex = 100;
    button.alpha = 1000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.onOkClick?.();
      this.unmountAfterLoadPopup();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  unmountAfterLoadPopup() {
    this.popup?.destroy();
  }
}
