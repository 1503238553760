export interface IEventDetails {
  stopPropagation: () => void,
  index: number,
}

export class GameEvent<T extends (event: IEventDetails, value: V) => void, V = Parameters<T>[1]> {
  private _listenersWithPriority: { listener: T, priority: number }[] = [];

  // Priority 0 is the default priority; higher priorities will be called first
  addEventListener(listener: T, priority: number = 0) {
    this._listenersWithPriority.push({ listener, priority });
    this._listenersWithPriority.sort((a, b) => b.priority - a.priority);
  }

  removeEventListener(listener: T) {
    this._listenersWithPriority = this._listenersWithPriority.filter((l) => l.listener !== listener);
  }

  async triggerEvent(value?: V) {
    let shouldStopPropagation = false;
    const event: IEventDetails = {
      stopPropagation: () => {
        shouldStopPropagation = true;
      },
      index: 0,
    };
    for (const { listener } of this._listenersWithPriority) {
      if (shouldStopPropagation)
        break;
      await listener(event, value as V);
      event.index++;
    }
  }
}
