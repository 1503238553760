import { Graphics, Sprite, Text, Texture } from 'pixi.js';
import { Dede } from '.';

export class AnteBetManager {
  anteBetPopup?: Graphics;

  constructor(private game: Dede) {
    this.game.onGoldenBetClick = () => {
      this.game.onClick.triggerEvent();
      if (this.game.anteBetActive) {
        this.game.anteBetActive = false;
      }
      else
        this.mountAnteBetPopup();
    };
  }

  mountAnteBetPopup() {
    console.log('mountAnteBetPopup');
    const graphics = new Graphics();
    this.anteBetPopup = graphics;
    const width = 600;
    const height = 200;
    const x = (this.game.width - width) / 2;
    const y = (this.game.height - height) / 2;
    graphics.x = x;
    graphics.y = y;
    graphics.width = width;
    graphics.height = height;
    const background = graphics.roundRect(0, 0, width, height, 20);
    const rect = graphics.roundRect(0, 0, width, height, 20);
    background.fill(0x9d34aa);
    background.alpha = 0.6;
    const text = new Text();
    rect.addChild(text);
    text.text = 'Activate AnteBet?';
    text.style = { fill: 0xffffff, fontSize: 40 };
    text.x = (this.anteBetPopup!.width - text.width) / 2;
    text.y = 40;
    text.alpha = 10000;

    this.initOkButton();

    this.initCancelButton();
    this.game.app.stage.addChild(graphics);
  }

  initCancelButton() {
    const buttonTexture = Texture.from('cancelButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.anteBetPopup!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.anchor.set(0.5);
    button.x = this.anteBetPopup!.width / 2 - 80;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.anteBetPopup?.destroy();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  initOkButton() {
    const buttonTexture = Texture.from('okButton'); // Use any button image
    const button = new Sprite(buttonTexture);
    this.anteBetPopup!.addChild(button);

    button.interactive = true;
    button.cursor = 'pointer'; // Set cursor to pointer on hover
    button.width = 120;
    button.height = 120;
    button.anchor.set(0.5);
    button.x = this.anteBetPopup!.width / 2 + 30;
    button.y = 140;
    button.zIndex = 100;
    button.alpha = 10000;

    button.on('pointerdown', () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
    });
    button.on('pointerup', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.game.anteBetActive = true;
      this.unmountAnteBetPopup();
    });
    button.on('pointerupoutside', () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  unmountAnteBetPopup() {
    this.anteBetPopup?.destroy();
  }
}
