import { Sprite, Texture } from 'pixi.js';
import GameController from '.';
import Button from '../button';
import {
  PORTRAIT_ADD_BET_BUTTON_CONTENT_X,
  PORTRAIT_ADD_BET_BUTTON_CONTENT_Y,
  PORTRAIT_ADD_BET_BUTTON_HEIGHT,
  PORTRAIT_ADD_BET_BUTTON_WIDTH,
  PORTRAIT_ADD_BET_BUTTON_X,
  PORTRAIT_ADD_BET_BUTTON_Y,
  PORTRAIT_AUTOPLAY_BUTTON_CONTENT_X,
  PORTRAIT_AUTOPLAY_BUTTON_CONTENT_Y,
  PORTRAIT_AUTOPLAY_BUTTON_HEIGHT,
  PORTRAIT_AUTOPLAY_BUTTON_WIDTH,
  PORTRAIT_AUTOPLAY_BUTTON_X,
  PORTRAIT_AUTOPLAY_BUTTON_Y,
  PORTRAIT_BUY_BONUS_BUTTON_CONTENT_HEIGHT,
  PORTRAIT_BUY_BONUS_BUTTON_CONTENT_WIDTH,
  PORTRAIT_BUY_BONUS_BUTTON_CONTENT_X,
  PORTRAIT_BUY_BONUS_BUTTON_CONTENT_Y,
  PORTRAIT_BUY_BONUS_BUTTON_HEIGHT,
  PORTRAIT_BUY_BONUS_BUTTON_WIDTH,
  PORTRAIT_BUY_BONUS_BUTTON_X,
  PORTRAIT_BUY_BONUS_BUTTON_Y,
  PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_HEIGHT,
  PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_WIDTH,
  PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_X,
  PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_Y,
  PORTRAIT_GOLDEN_BET_BUTTON_HEIGHT,
  PORTRAIT_GOLDEN_BET_BUTTON_WIDTH,
  PORTRAIT_GOLDEN_BET_BUTTON_X,
  PORTRAIT_GOLDEN_BET_BUTTON_Y,
  PORTRAIT_INFO_BUTTON_CONTENT_X,
  PORTRAIT_INFO_BUTTON_CONTENT_Y,
  PORTRAIT_INFO_BUTTON_HEIGHT,
  PORTRAIT_INFO_BUTTON_WIDTH,
  PORTRAIT_INFO_BUTTON_X,
  PORTRAIT_INFO_BUTTON_Y,
  PORTRAIT_SPIN_BUTTON_CONTENT_X,
  PORTRAIT_SPIN_BUTTON_CONTENT_Y,
  PORTRAIT_SPIN_BUTTON_HEIGHT,
  PORTRAIT_SPIN_BUTTON_WIDTH,
  PORTRAIT_SPIN_BUTTON_X,
  PORTRAIT_SPIN_BUTTON_Y,
  PORTRAIT_SPIN_CONTAINER_HEIGHT,
  PORTRAIT_SPIN_CONTAINER_WIDTH,
  PORTRAIT_SPIN_CONTAINER_X,
  PORTRAIT_SPIN_CONTAINER_Y,
  PORTRAIT_STAKE_BUTTON_CONTENT_X,
  PORTRAIT_STAKE_BUTTON_CONTENT_Y,
  PORTRAIT_STAKE_BUTTON_HEIGHT,
  PORTRAIT_STAKE_BUTTON_WIDTH,
  PORTRAIT_STAKE_BUTTON_X,
  PORTRAIT_STAKE_BUTTON_Y,
  PORTRAIT_SUB_BET_BUTTON_CONTENT_X,
  PORTRAIT_SUB_BET_BUTTON_CONTENT_Y,
  PORTRAIT_SUB_BET_BUTTON_HEIGHT,
  PORTRAIT_SUB_BET_BUTTON_WIDTH,
  PORTRAIT_SUB_BET_BUTTON_X,
  PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_X,
  PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_Y,
  PORTRAIT_TURBO_SPIN_BUTTON_HEIGHT,
  PORTRAIT_TURBO_SPIN_BUTTON_WIDTH,
  PORTRAIT_TURBO_SPIN_BUTTON_X,
  PORTRAIT_TURBO_SPIN_BUTTON_Y,
} from './constants';

export const mountPortraitButtons = (controller: GameController) => {
  controller.buyBonusButton = new Button(controller.container, {
    backgroundTexture: 'bonusButtonBackground',
    texture: 'buyBonus',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.runReels(true);
    },
    backgroundPosition: {
      x: PORTRAIT_BUY_BONUS_BUTTON_X,
      y: PORTRAIT_BUY_BONUS_BUTTON_Y,
      width: PORTRAIT_BUY_BONUS_BUTTON_WIDTH,
      height: PORTRAIT_BUY_BONUS_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_BUY_BONUS_BUTTON_CONTENT_X,
      y: PORTRAIT_BUY_BONUS_BUTTON_CONTENT_Y,
      width: PORTRAIT_BUY_BONUS_BUTTON_CONTENT_WIDTH,
      height: PORTRAIT_BUY_BONUS_BUTTON_CONTENT_HEIGHT,
    },
  });
  controller.goldenBetButton = new Button(controller.container, {
    backgroundTexture: 'bonusButtonBackground',
    texture: 'goldenBet',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onGoldenBetClick();
    },
    backgroundPosition: {
      x: PORTRAIT_GOLDEN_BET_BUTTON_X,
      y: PORTRAIT_GOLDEN_BET_BUTTON_Y,
      width: PORTRAIT_GOLDEN_BET_BUTTON_WIDTH,
      height: PORTRAIT_GOLDEN_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_X,
      y: PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_Y,
      width: PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_WIDTH,
      height: PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_HEIGHT,
    },
  });

  controller.addBetButton = new Button(controller.container, {
    backgroundTexture: 'buttonBackground',
    texture: '-',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const currentIndex = controller.game.config.limits.legalBets.indexOf(controller.game.stake) || 0;
      controller.game.stake
        = controller.game.config.limits.legalBets[
          (currentIndex - 1 + controller.game.config.limits.legalBets.length)
          % controller.game.config.limits.legalBets.length
        ];
    },
    backgroundPosition: {
      x: PORTRAIT_ADD_BET_BUTTON_X,
      y: PORTRAIT_ADD_BET_BUTTON_Y,
      width: PORTRAIT_ADD_BET_BUTTON_WIDTH,
      height: PORTRAIT_ADD_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_ADD_BET_BUTTON_CONTENT_X,
      y: PORTRAIT_ADD_BET_BUTTON_CONTENT_Y,
    },
  });
  controller.subBetButton = new Button(controller.container, {
    backgroundTexture: 'buttonBackground',
    texture: '+',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      const currentIndex = controller.game.config.limits.legalBets.indexOf(controller.game.stake) || 0;
      controller.game.stake
        = controller.game.config.limits.legalBets[(currentIndex + 1) % controller.game.config.limits.legalBets.length];
    },
    backgroundPosition: {
      x: PORTRAIT_SUB_BET_BUTTON_X,
      y: PORTRAIT_SUB_BET_BUTTON_X,
      width: PORTRAIT_SUB_BET_BUTTON_WIDTH,
      height: PORTRAIT_SUB_BET_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_SUB_BET_BUTTON_CONTENT_X,
      y: PORTRAIT_SUB_BET_BUTTON_CONTENT_Y,
    },
  });

  controller.spinContainer = new Sprite(Texture.from('spinContainer'));
  controller.container.addChild(controller.spinContainer);
  controller.spinContainer.x = PORTRAIT_SPIN_CONTAINER_X;
  controller.spinContainer.y = PORTRAIT_SPIN_CONTAINER_Y;
  controller.spinContainer.width = PORTRAIT_SPIN_CONTAINER_WIDTH;
  controller.spinContainer.height = PORTRAIT_SPIN_CONTAINER_HEIGHT;

  controller.turboSpinButton = new Button(controller.container, {
    backgroundTexture: 'turboSpinBackground',
    texture: 'turboSpin',
    onClick: () => {
      controller.game.onClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_TURBO_SPIN_BUTTON_X,
      y: PORTRAIT_TURBO_SPIN_BUTTON_Y,
      width: PORTRAIT_TURBO_SPIN_BUTTON_WIDTH,
      height: PORTRAIT_TURBO_SPIN_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_X,
      y: PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_Y,
    },
  });
  controller.stakeButton = new Button(controller.container, {
    backgroundTexture: 'betAmountBackground',
    text: `$${controller.game.stake}`,
    fontSize: 48,
    onClick: () => {
      controller.game.onClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_STAKE_BUTTON_X,
      y: PORTRAIT_STAKE_BUTTON_Y,
      width: PORTRAIT_STAKE_BUTTON_WIDTH,
      height: PORTRAIT_STAKE_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_STAKE_BUTTON_CONTENT_X,
      y: PORTRAIT_STAKE_BUTTON_CONTENT_Y,
    },
  });

  controller.spinButton = new Button(controller.container, {
    backgroundTexture: 'spinBackground',
    texture: 'spin',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.runReels();
    },
    backgroundPosition: {
      x: PORTRAIT_SPIN_BUTTON_X,
      y: PORTRAIT_SPIN_BUTTON_Y,
      width: PORTRAIT_SPIN_BUTTON_WIDTH,
      height: PORTRAIT_SPIN_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_SPIN_BUTTON_CONTENT_X,
      y: PORTRAIT_SPIN_BUTTON_CONTENT_Y,
    },
  });

  controller.infoButton = new Button(controller.container, {
    backgroundTexture: 'infoButton',
    onClick: () => {
      controller.game.onClick.triggerEvent();
      controller.game.onInfoButtonClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_INFO_BUTTON_X,
      y: PORTRAIT_INFO_BUTTON_Y,
      width: PORTRAIT_INFO_BUTTON_WIDTH,
      height: PORTRAIT_INFO_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_INFO_BUTTON_CONTENT_X,
      y: PORTRAIT_INFO_BUTTON_CONTENT_Y,
    },
  });

  controller.autoPlayButton = new Button(controller.container, {
    backgroundTexture: 'autoPlay',
    onClick: () => {
      controller.game.onClick.triggerEvent();
    },
    backgroundPosition: {
      x: PORTRAIT_AUTOPLAY_BUTTON_X,
      y: PORTRAIT_AUTOPLAY_BUTTON_Y,
      width: PORTRAIT_AUTOPLAY_BUTTON_WIDTH,
      height: PORTRAIT_AUTOPLAY_BUTTON_HEIGHT,
    },
    contentPosition: {
      x: PORTRAIT_AUTOPLAY_BUTTON_CONTENT_X,
      y: PORTRAIT_AUTOPLAY_BUTTON_CONTENT_Y,
    },
  });
};
