export const PORTRAIT_WIDTH = 900;
export const PORTRAIT_HEIGHT = 400;
export const LANDSCAPE_WIDTH = 450;
export const LANDSCAPE_HEIGHT = 600;
export const PORTRAIT_Y_OFFSET = 20;

export const PORTRAIT_BUY_BONUS_BUTTON_X = 300;
export const PORTRAIT_BUY_BONUS_BUTTON_Y = 140;
export const PORTRAIT_BUY_BONUS_BUTTON_WIDTH = 300;
export const PORTRAIT_BUY_BONUS_BUTTON_HEIGHT = 180;
export const PORTRAIT_BUY_BONUS_BUTTON_CONTENT_X = -40;
export const PORTRAIT_BUY_BONUS_BUTTON_CONTENT_Y = 1;
export const PORTRAIT_BUY_BONUS_BUTTON_CONTENT_WIDTH = 110;
export const PORTRAIT_BUY_BONUS_BUTTON_CONTENT_HEIGHT = 75;
export const PORTRAIT_GOLDEN_BET_BUTTON_X = 600;
export const PORTRAIT_GOLDEN_BET_BUTTON_Y = 140;
export const PORTRAIT_GOLDEN_BET_BUTTON_WIDTH = 300;
export const PORTRAIT_GOLDEN_BET_BUTTON_HEIGHT = 180;
export const PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_X = 40;
export const PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_Y = 1;
export const PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_WIDTH = 110;
export const PORTRAIT_GOLDEN_BET_BUTTON_CONTENT_HEIGHT = 75;
export const PORTRAIT_ADD_BET_BUTTON_X = 300;
export const PORTRAIT_ADD_BET_BUTTON_Y = 250;
export const PORTRAIT_ADD_BET_BUTTON_WIDTH = 180;
export const PORTRAIT_ADD_BET_BUTTON_HEIGHT = 120;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_X = -40;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_Y = 1;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_WIDTH = 110;
export const PORTRAIT_ADD_BET_BUTTON_CONTENT_HEIGHT = 75;
export const PORTRAIT_SUB_BET_BUTTON_X = 600;
export const PORTRAIT_SUB_BET_BUTTON_Y = 250;
export const PORTRAIT_SUB_BET_BUTTON_WIDTH = 180;
export const PORTRAIT_SUB_BET_BUTTON_HEIGHT = 120;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_X = 40;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_Y = 1;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_WIDTH = 110;
export const PORTRAIT_SUB_BET_BUTTON_CONTENT_HEIGHT = 75;
export const PORTRAIT_SPIN_CONTAINER_X = 300;
export const PORTRAIT_SPIN_CONTAINER_Y = 40;
export const PORTRAIT_SPIN_CONTAINER_WIDTH = 300;
export const PORTRAIT_SPIN_CONTAINER_HEIGHT = 300;
export const PORTRAIT_TURBO_SPIN_BUTTON_X = 450;
export const PORTRAIT_TURBO_SPIN_BUTTON_Y = 60;
export const PORTRAIT_TURBO_SPIN_BUTTON_WIDTH = 160;
export const PORTRAIT_TURBO_SPIN_BUTTON_HEIGHT = 180;
export const PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_X = 0;
export const PORTRAIT_TURBO_SPIN_BUTTON_CONTENT_Y = -30;
export const PORTRAIT_STAKE_BUTTON_X = 450;
export const PORTRAIT_STAKE_BUTTON_Y = 280;
export const PORTRAIT_STAKE_BUTTON_WIDTH = 210;
export const PORTRAIT_STAKE_BUTTON_HEIGHT = 180;
export const PORTRAIT_STAKE_BUTTON_CONTENT_X = 0;
export const PORTRAIT_STAKE_BUTTON_CONTENT_Y = 60;
export const PORTRAIT_SPIN_BUTTON_X = 450;
export const PORTRAIT_SPIN_BUTTON_Y = 190;
export const PORTRAIT_SPIN_BUTTON_WIDTH = 220;
export const PORTRAIT_SPIN_BUTTON_HEIGHT = 220;
export const PORTRAIT_SPIN_BUTTON_CONTENT_X = 0;
export const PORTRAIT_SPIN_BUTTON_CONTENT_Y = 0;
export const PORTRAIT_INFO_BUTTON_X = 100;
export const PORTRAIT_INFO_BUTTON_Y = 260;
export const PORTRAIT_INFO_BUTTON_WIDTH = 100;
export const PORTRAIT_INFO_BUTTON_HEIGHT = 100;
export const PORTRAIT_INFO_BUTTON_CONTENT_X = 0;
export const PORTRAIT_INFO_BUTTON_CONTENT_Y = 0;
export const PORTRAIT_AUTOPLAY_BUTTON_X = 800;
export const PORTRAIT_AUTOPLAY_BUTTON_Y = 260;
export const PORTRAIT_AUTOPLAY_BUTTON_WIDTH = 150;
export const PORTRAIT_AUTOPLAY_BUTTON_HEIGHT = 150;
export const PORTRAIT_AUTOPLAY_BUTTON_CONTENT_X = 0;
export const PORTRAIT_AUTOPLAY_BUTTON_CONTENT_Y = 0;

export const LANDSCAPE_BUY_BONUS_BUTTON_X = 150;
export const LANDSCAPE_BUY_BONUS_BUTTON_Y = 50;
export const LANDSCAPE_BUY_BONUS_BUTTON_WIDTH = 250;
export const LANDSCAPE_BUY_BONUS_BUTTON_HEIGHT = 180;
export const LANDSCAPE_BUY_BONUS_BUTTON_CONTENT_X = -40;
export const LANDSCAPE_BUY_BONUS_BUTTON_CONTENT_Y = 1;
export const LANDSCAPE_BUY_BONUS_BUTTON_CONTENT_WIDTH = 110;
export const LANDSCAPE_BUY_BONUS_BUTTON_CONTENT_HEIGHT = 75;
export const LANDSCAPE_GOLDEN_BET_BUTTON_X = 350;
export const LANDSCAPE_GOLDEN_BET_BUTTON_Y = 50;
export const LANDSCAPE_GOLDEN_BET_BUTTON_WIDTH = 250;
export const LANDSCAPE_GOLDEN_BET_BUTTON_HEIGHT = 180;
export const LANDSCAPE_GOLDEN_BET_BUTTON_CONTENT_X = 40;
export const LANDSCAPE_GOLDEN_BET_BUTTON_CONTENT_Y = 1;
export const LANDSCAPE_GOLDEN_BET_BUTTON_CONTENT_WIDTH = 110;
export const LANDSCAPE_GOLDEN_BET_BUTTON_CONTENT_HEIGHT = 75;
export const LANDSCAPE_ADD_BET_BUTTON_X = 120;
export const LANDSCAPE_ADD_BET_BUTTON_Y = 250;
export const LANDSCAPE_ADD_BET_BUTTON_WIDTH = 180;
export const LANDSCAPE_ADD_BET_BUTTON_HEIGHT = 120;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_X = -40;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_Y = 1;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_WIDTH = 110;
export const LANDSCAPE_ADD_BET_BUTTON_CONTENT_HEIGHT = 75;
export const LANDSCAPE_SUB_BET_BUTTON_X = 380;
export const LANDSCAPE_SUB_BET_BUTTON_Y = 250;
export const LANDSCAPE_SUB_BET_BUTTON_WIDTH = 180;
export const LANDSCAPE_SUB_BET_BUTTON_HEIGHT = 120;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_X = 40;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_Y = 1;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_WIDTH = 110;
export const LANDSCAPE_SUB_BET_BUTTON_CONTENT_HEIGHT = 75;
export const LANDSCAPE_SPIN_CONTAINER_X = 100;
export const LANDSCAPE_SPIN_CONTAINER_Y = 40;
export const LANDSCAPE_SPIN_CONTAINER_WIDTH = 300;
export const LANDSCAPE_SPIN_CONTAINER_HEIGHT = 300;
export const LANDSCAPE_TURBO_SPIN_BUTTON_X = 250;
export const LANDSCAPE_TURBO_SPIN_BUTTON_Y = 60;
export const LANDSCAPE_TURBO_SPIN_BUTTON_WIDTH = 160;
export const LANDSCAPE_TURBO_SPIN_BUTTON_HEIGHT = 180;
export const LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_TURBO_SPIN_BUTTON_CONTENT_Y = -30;
export const LANDSCAPE_STAKE_BUTTON_X = 250;
export const LANDSCAPE_STAKE_BUTTON_Y = 280;
export const LANDSCAPE_STAKE_BUTTON_WIDTH = 210;
export const LANDSCAPE_STAKE_BUTTON_HEIGHT = 180;
export const LANDSCAPE_STAKE_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_STAKE_BUTTON_CONTENT_Y = 60;
export const LANDSCAPE_SPIN_BUTTON_X = 250;
export const LANDSCAPE_SPIN_BUTTON_Y = 190;
export const LANDSCAPE_SPIN_BUTTON_WIDTH = 220;
export const LANDSCAPE_SPIN_BUTTON_HEIGHT = 220;
export const LANDSCAPE_SPIN_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_SPIN_BUTTON_CONTENT_Y = 0;
export const LANDSCAPE_INFO_BUTTON_X = 100;
export const LANDSCAPE_INFO_BUTTON_Y = 460;
export const LANDSCAPE_INFO_BUTTON_WIDTH = 100;
export const LANDSCAPE_INFO_BUTTON_HEIGHT = 100;
export const LANDSCAPE_INFO_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_INFO_BUTTON_CONTENT_Y = 0;
export const LANDSCAPE_AUTOPLAY_BUTTON_X = 400;
export const LANDSCAPE_AUTOPLAY_BUTTON_Y = 460;
export const LANDSCAPE_AUTOPLAY_BUTTON_WIDTH = 150;
export const LANDSCAPE_AUTOPLAY_BUTTON_HEIGHT = 150;
export const LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_X = 0;
export const LANDSCAPE_AUTOPLAY_BUTTON_CONTENT_Y = 0;
