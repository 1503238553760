import { Sprite, Texture } from 'pixi.js';
import { Dede } from '.';
import { IFreeSpinStatus } from '../types';

export class BackgroundManager {
  sprite?: Sprite;
  freeSpinStatus?: IFreeSpinStatus;
  constructor(private game: Dede) {}

  async init() {
    await this.game.app.init({
      background: '#EA95EDFF',
    });
    this.sprite = new Sprite(Texture.from('background'));
    const initialWidth = this.sprite.width;
    const initialHeight = this.sprite.height + 500;

    this.game.onResize.addEventListener((event, newSize) => {
      if (!this.sprite)
        return;
      const orientation = this.game.getOrientation();
      if (orientation === 'portrait') {
        const scale = newSize.height / initialHeight;
        const scaledWidth = initialWidth * scale;
        const xOffset = (scaledWidth - newSize.width) / 2;
        this.sprite.width = scaledWidth;
        this.sprite.height = newSize.height;
        this.sprite.y = 0;
        this.sprite.x = -xOffset;
      }
      else {
        const scale = newSize.width / initialWidth;
        const scaledHeight = initialHeight * scale;
        const yOffset = (scaledHeight - newSize.height) / 2;
        this.sprite.width = newSize.width;
        this.sprite.height = scaledHeight;
        this.sprite.y = -yOffset;
        this.sprite.x = 0;
      }
    });
    this.game.onFreeSpinChange.addEventListener((event, freeSpinStatus) => {
      if (!this.sprite)
        return;
      if (this.freeSpinStatus?.active !== freeSpinStatus.active) {
        if (freeSpinStatus.active) {
          this.sprite.texture = Texture.from('backgroundFreeSpin');
        }
        else {
          this.sprite.texture = Texture.from('background');
        }
      }
    });

    this.game.app.stage.addChild(this.sprite);
  }
}
