import { Graphics, Sprite } from 'pixi.js';
import { Game } from '../../../game';
import Button from '../button';
import { LANDSCAPE_HEIGHT, LANDSCAPE_WIDTH, PORTRAIT_HEIGHT, PORTRAIT_WIDTH, PORTRAIT_Y_OFFSET } from './constants';
import { mountPortraitButtons } from './portraitButtons';
import { mountLandscapeButtons } from './landscapeButtons';
import { Orientation } from '../../../types';

export default class GameController {
  container = new Graphics();
  width = 0;
  height = 0;

  buyBonusButton!: Button;
  goldenBetButton!: Button;
  addBetButton!: Button;
  subBetButton!: Button;
  turboSpinButton!: Button;
  stakeButton!: Button;
  spinButton!: Button;
  infoButton!: Button;
  autoPlayButton!: Button;
  spinContainer!: Sprite;
  orientation!: Orientation;

  constructor(public game: Game) {
    this.container.width = this.width;
    this.container.height = this.height;
  }

  handleResize = () => {
    const orientation = this.game.getOrientation();

    if (orientation === 'portrait') {
      this.width = PORTRAIT_WIDTH;
      this.height = PORTRAIT_HEIGHT;
    }
    else {
      this.width = LANDSCAPE_WIDTH;
      this.height = LANDSCAPE_HEIGHT;
    }
    if (orientation === 'portrait') {
      this.container.x = this.game.width / 2 - this.width / 2;
      this.container.y = this.game.height - this.height - PORTRAIT_Y_OFFSET;
    }
    else {
      this.container.x = this.game.width - this.width;
      this.container.y = (this.game.height - this.height) / 2;
    }
    if (orientation !== this.orientation) {
      this.mountButtons();
      this.orientation = orientation;
    }
  };

  destroyButtons() {
    this.buyBonusButton?.destroy();
    this.goldenBetButton?.destroy();
    this.addBetButton?.destroy();
    this.subBetButton?.destroy();
    this.turboSpinButton?.destroy();
    this.stakeButton?.destroy();
    this.spinButton?.destroy();
    this.infoButton?.destroy();
    this.autoPlayButton?.destroy();
    this.spinContainer?.destroy();
  }

  mountButtons() {
    this.destroyButtons();
    const orientation = this.game.getOrientation();
    if (orientation === 'portrait') {
      mountPortraitButtons(this);
    }
    else {
      mountLandscapeButtons(this);
    }
  }

  mount() {
    this.game.app.stage.addChild(this.container);

    this.handleResize();
    this.game.onResize.addEventListener(this.handleResize);

    this.mountButtons();
    this.orientation = this.game.getOrientation();

    this.game.onStakeChange.addEventListener((event, stake) => {
      this.stakeButton.text = `$${stake}`;
    });

    this.game.onSpin.addEventListener(() => {
      this.spinButton.disabled = true;
      this.stakeButton.disabled = true;
      this.addBetButton.disabled = true;
      this.subBetButton.disabled = true;
      this.turboSpinButton.disabled = true;
      this.buyBonusButton.disabled = true;
      this.goldenBetButton.disabled = true;
      // this.container.alpha = 0.5;
    });

    this.game.onSpinComplete.addEventListener(() => {
      this.spinButton.disabled = false;
      this.stakeButton.disabled = false;
      this.addBetButton.disabled = false;
      this.subBetButton.disabled = false;
      this.turboSpinButton.disabled = false;
      this.buyBonusButton.disabled = false;
      this.goldenBetButton.disabled = false;
      // this.container.alpha = 1;
    });
  }
}
